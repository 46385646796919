
/* this adjusts the style all the controls */
.gm-style .controls {
  font-size: 28px; 
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 300;
  height: 1em;
  margin: 6px;
  text-align: center;
  user-select: none;
  padding: 2px;
  width: 1em;
}

.gm-style .controls button {
  border: 0;
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
}

.gm-style .controls button:hover {
  color: rgba(0, 0, 0, 0.9);
}

.gm-style .controls.zoom-control {
  display: flex;
  flex-direction: column;
  height: auto;
}

.gm-style .controls.zoom-control button {
  font: 0.85em Arial;
  margin: 1px;
  padding: 0;
}

.gm-style .controls.maptype-control {
  display: flex;
  flex-direction: row;
  width: auto;
}

.gm-style .controls.maptype-control select {
  font-size: 0.5em !important;
  margin: 1px;
  padding: 0;
  border: none;
  background: #fff;
}

.gm-style .controls.maptype-control.maptype-control-is-map .maptype-control-map {
  font-weight: 700;
}

.gm-style .controls.maptype-control.maptype-control-is-satellite .maptype-control-satellite {
  font-weight: 700;
}

.controls.geolocation-control, .controls.imagery-control, .controls.utility-legend-control {
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
}

.geolocation-control img, .imagery-control img, .utility-legend-control img {
  width: 100%;
  cursor: pointer;
  opacity: 0.6;
}

.controls.utility-legend-control {
  display: none;
}

